.container {
  width: 100%;
  max-width: 1600px;
  margin-inline: auto;
  padding-inline: calc(8px + 1.5625vw);
}
.section-padding {
  padding-block: calc(40px + 1.5625vh);
}
.flex {
  display: flex;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.items-start {
  align-items: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-wrap {
  flex-wrap: wrap;
}
.overflow-x-auto {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.w-100 {
  width: 100%;
}
.form-input {
  border-radius: 0;
  background-color: transparent;
  border-bottom: 1px solid hsl(var(--storm-grey));
  font-size: 1.1rem;
  font-weight: 600;
}

.bg {
  &-green {
    background-color: hsl(var(--pistachio));
  }
}
.text {
  &-white {
    color: hsl(var(--white));
  }
  &-muted {
    color: #bdbdbd;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &--1 {
    -webkit-line-clamp: 1;
  }
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}

@for $i from 1 through 8 {
  .gap-#{$i} {
    gap: #{$i * 0.62}rem;
  }
}
@for $i from 1 through 8 {
  .rounded-#{$i} {
    border-radius: #{$i * 0.13}rem;
  }
}

@for $i from 1 through 8 {
  .ml-#{$i * 5} {
    margin-left: #{$i * 5}px !important;
  }
  .mr-#{$i * 5} {
    margin-right: #{$i * 5}px !important;
  }
  .mt-#{$i * 5} {
    margin-top: #{$i * 5}px !important;
  }

  .mb-#{$i * 5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .my-#{$i * 5} {
    margin-top: #{$i * 5}px !important;
    margin-bottom: #{$i * 5}px !important;
  }
  .mx-#{$i * 5} {
    margin-right: #{$i * 5}px !important;
    margin-left: #{$i * 5}px !important;
  }

  .pl-#{$i * 5} {
    padding-left: #{$i * 5}px !important;
  }
  .pr-#{$i * 5} {
    padding-right: #{$i * 5}px !important;
  }
  .pt-#{$i * 5} {
    padding-top: #{$i * 5}px !important;
  }

  .pb-#{$i * 5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .py-#{$i * 5} {
    padding-top: #{$i * 5}px !important;
    padding-bottom: #{$i * 5}px !important;
  }
  .px-#{$i * 5} {
    padding-right: #{$i * 5}px !important;
    padding-left: #{$i * 5}px !important;
  }
}
.d-none {
  display: none;
}

@include min-lg {
  .d-lg-inline {
    display: inline-block;
  }
  .blur-wrap {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      pointer-events: none;
      bottom: 0;
      right: 0;
      // background: linear-gradient(
      //   279deg,
      //   rgb(252, 252, 252) 31%,
      //   rgba(255, 255, 255, 0.5382528011) 54%,
      //   rgba(255, 255, 255, 0) 76%
      // );
      background-image: radial-gradient(
        circle at 112% 34%,
        #ffffff 2%,
        rgb(252 252 252) 14%,
        hsla(248.57deg, 53.85%, 5.1%, 0) 57%
      );
    }
    &__horizontal {
      &::after {
        top: 0;
        height: 100%;
        width: 30%;
      }
    }
  }
}
