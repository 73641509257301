.date-cards-wrapper {
  min-height: 70vh;
  overflow-y: hidden;
  padding: 0.75rem;
}
.date-card {
  //background-color: var(--white);
  box-shadow: var(--shadow-main);
  position: relative;
  padding: 1.3rem 1rem;
  border-radius: 6px;
  min-width: 350px;
  max-width: 350px;
  max-height: 80vh;
  overflow-y: auto;

  .top-bar {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 6px 6px 0 0;
    height: 0.3rem;
  }
}
@include max-md {
  .date-card {
    min-width: 300px;
    max-width: 300px;
    scroll-snap-align: start;
  }
}
