.accordions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 24px;
}

.accordion {
  background-color: hsl(var(--gray));
  border-radius: 8px;
  font-family: var(--primary-font);

  & &__header {
    width: 100%;
    cursor: pointer;
    position: relative;
    color: hsl(var(--storm-grey));
    font-size: 1rem;
    font-weight: 500;
    padding: 1.1rem 25px 1.1rem 15px;
    background-color: hsl(var(--white-smoke));
    border-radius: 5px;
    border-bottom: 1px solid transparent;

    .text {
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
    }
    &::after {
      content: '';
      position: absolute;
      right: 0.75rem;
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg);
      background-size: 20px;
      background-position: center;
      transition: 0.3s;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.352 5.71325C14.5557 5.51257 14.8307 5.4 15.1174 5.4C15.404 5.4 15.679 5.51257 15.8827 5.71325C15.9833 5.81179 16.0631 5.92925 16.1176 6.05879C16.1721 6.18834 16.2002 6.32737 16.2002 6.46781C16.2002 6.60825 16.1721 6.74729 16.1176 6.87683C16.0631 7.00637 15.9833 7.12383 15.8827 7.22238L9.76631 13.1872C9.56217 13.3876 9.28695 13.5 9.00019 13.5C8.71344 13.5 8.43822 13.3876 8.23408 13.1872L2.11769 7.22237C2.01714 7.12383 1.93728 7.00637 1.88278 6.87683C1.82827 6.74729 1.8002 6.60825 1.8002 6.46781C1.8002 6.32737 1.82827 6.18833 1.88278 6.05879C1.93728 5.92925 2.01714 5.81179 2.11769 5.71325C2.32183 5.51285 2.59705 5.40049 2.88381 5.40049C3.17056 5.40049 3.44579 5.51285 3.64992 5.71325L9.00254 10.6051L14.352 5.71325Z' fill='%23707090'/%3E%3C/svg%3E%0A");
    }
  }
  & &__body {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 300ms ease;
    background-color: hsl(var(--white-smoke));
    .content {
      overflow: hidden;
      font-weight: 500;
      > div {
        padding: 0.75rem 15px 0.75rem 15px;
      }
    }
  }

  &.open {
    & .accordion__header {
      border-radius: 5px 5px 0 0;
      border-bottom: 1px solid hsl(var(--storm-grey), 0.1);
      &::after {
        transform: translateY(-50%) rotate(0);
      }
    }
    & .accordion__body {
      grid-template-rows: 1fr;
    }
  }
}
