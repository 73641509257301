@mixin sm {
  @media (min-width: #{$sm}) and (max-width: #{$md - 1px}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$md}) and (max-width: #{$lg - 1px}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$lg}) and (max-width: #{$xl - 1px}) {
    @content;
  }
}
@mixin min-sm {
  @media (min-width: #{$sm}) {
    @content;
  }
}
@mixin min-md {
  @media (min-width: #{$md}) {
    @content;
  }
}

@mixin min-lg {
  @media (min-width: #{$lg}) {
    @content;
  }
}

@mixin min-xl {
  @media (min-width: #{$xl}) {
    @content;
  }
}
@mixin min-xxl {
  @media (min-width: #{$xxl}) {
    @content;
  }
}

@mixin max-sm {
  @media (max-width: #{$sm}) {
    @content;
  }
}
@mixin max-md {
  @media (max-width: #{$md}) {
    @content;
  }
}

@mixin max-lg {
  @media (max-width: #{$lg}) {
    @content;
  }
}

@mixin max-xl {
  @media (max-width: #{$xl}) {
    @content;
  }
}
@mixin max-xxl {
  @media (max-width: #{$xxl}) {
    @content;
  }
}

@mixin min-width($min-width) {
  @media (min-width: #{$min-width}) {
    @content;
  }
}

@mixin max-width($max-width) {
  @media (max-width: #{$max-width}) {
    @content;
  }
}