@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');


:root {
  --white           : 0, 0%, 100%;
  --soapstone       : 0, 0%, 99%;
  --white-smoke     : 0, 0%, 96%;
  --muted-color     : 0, 0%, 68%;
  --storm-grey      : 240, 13%, 50%;
  --black           : 206, 21%, 15%;
  --pistachio       : 123, 38%, 65%;

  --primary-font    :'Inter', sans-serif;

  --shadow-main     :rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

//breakpoints
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1200px;
$xxl: 1440px;
