html {
  font-family: var(--primary-font);
  font-size: 100%;
  scroll-behavior: smooth;
}
body {
  color: hsl(var(--storm-grey));
  background-color: hsl(var(--soapstone));
}
* {
  box-sizing: border-box;
}
h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
  line-height: 1.3;
}
button {
  font-family: var(--primary-font);
}

h1,
.h1 {
  font-size: 3.052rem;
}

h2,
.h2 {
  font-size: 2.441rem;
}

h3,
.h3 {
  font-size: 1.953rem;
}

h4,
.h4 {
  font-size: 1.563rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}
h6,
.h6 {
  font-size: 1rem;
}
small {
  font-size: 0.9rem;
}

input[type='search'] {
  padding: 0.2em 0.4em;
}

input[type='search'].dark {
  background: #222;
  color: #fff;
}

input[type='search'].light {
  background: #fff;
  color: #222;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 1em;
  width: 1em;
  border-radius: 50em;
  background: url(https://pro.fontawesome.com/releases/v5.10.0/svgs/solid/times-circle.svg)
    no-repeat 50% 50%;
  background-size: contain;
  opacity: 0;
  cursor: pointer;
}

input[type='search']:focus::-webkit-search-cancel-button {
  opacity: 0.5;
  pointer-events: all;
}

input[type='search'].dark::-webkit-search-cancel-button {
  filter: invert(1);
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
button[disabled] {
  opacity: 0.7;
}
button:not([disabled]) {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 7px;
  height: 8px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: hsl(var(--muted-color), 0.2);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: hsl(var(--muted-color), 0.5);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(var(--muted-color), 0.8);
}
